 const determinePrimaryColor = () => {
  console.log("ENV_VAR", import.meta.env.VITE_ENV_VAR)
  switch (import.meta.env.VITE_ENV_VAR) {
    case "development":
      return "#3abff8";
    case "production":
      return "#ff7600";
    default:
      return "#f54242";
  }
};
export default determinePrimaryColor;