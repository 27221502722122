import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { debounce } from "@mui/material/utils";
import React, { useCallback } from "react";

const DebouncedButton = ({
  children,
  debounceTime = 500,
  onClick,
  loading,
  ...props
}: {
  children: string | JSX.Element;
  debounceTime: number;
  onClick: any;
  loading: boolean;
} & ButtonProps) => {
  const debouncedApprove = useCallback(
    debounce(() => onClick(), debounceTime),
    []
  );
  return (
    <Button onClick={debouncedApprove} {...props}>
      {loading ? <CircularProgress color="primary" size={22} /> : children}
    </Button>
  );
};

export default DebouncedButton;
