import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUserLocationMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
  locationId: Types.Scalars['Int'];
  locationIdentifier?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateUserLocationMutation = { update_auth_user_by_pk?: { id: any } | null };

export type UpdateUserEmployeeMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid'];
  employeeId: Types.Scalars['Int'];
  EmployeeIdentifier?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateUserEmployeeMutation = { update_auth_user_by_pk?: { id: any } | null };


export const UpdateUserLocationDocument = gql`
    mutation UpdateUserLocation($userId: uuid!, $locationId: Int!, $locationIdentifier: String) {
  update_auth_user_by_pk(
    pk_columns: {id: $userId}
    _set: {ons_location_id: $locationId, ons_location_identifier: $locationIdentifier}
  ) {
    id
  }
}
    `;
export type UpdateUserLocationMutationFn = Apollo.MutationFunction<UpdateUserLocationMutation, UpdateUserLocationMutationVariables>;

/**
 * __useUpdateUserLocationMutation__
 *
 * To run a mutation, you first call `useUpdateUserLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLocationMutation, { data, loading, error }] = useUpdateUserLocationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      locationId: // value for 'locationId'
 *      locationIdentifier: // value for 'locationIdentifier'
 *   },
 * });
 */
export function useUpdateUserLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserLocationMutation, UpdateUserLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserLocationMutation, UpdateUserLocationMutationVariables>(UpdateUserLocationDocument, options);
      }
export type UpdateUserLocationMutationHookResult = ReturnType<typeof useUpdateUserLocationMutation>;
export type UpdateUserLocationMutationResult = Apollo.MutationResult<UpdateUserLocationMutation>;
export type UpdateUserLocationMutationOptions = Apollo.BaseMutationOptions<UpdateUserLocationMutation, UpdateUserLocationMutationVariables>;
export const UpdateUserEmployeeDocument = gql`
    mutation UpdateUserEmployee($userId: uuid!, $employeeId: Int!, $EmployeeIdentifier: String) {
  update_auth_user_by_pk(
    pk_columns: {id: $userId}
    _set: {ons_employee_id: $employeeId, ons_employee_identifier: $EmployeeIdentifier}
  ) {
    id
  }
}
    `;
export type UpdateUserEmployeeMutationFn = Apollo.MutationFunction<UpdateUserEmployeeMutation, UpdateUserEmployeeMutationVariables>;

/**
 * __useUpdateUserEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmployeeMutation, { data, loading, error }] = useUpdateUserEmployeeMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      employeeId: // value for 'employeeId'
 *      EmployeeIdentifier: // value for 'EmployeeIdentifier'
 *   },
 * });
 */
export function useUpdateUserEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserEmployeeMutation, UpdateUserEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateUserEmployeeMutation, UpdateUserEmployeeMutationVariables>(UpdateUserEmployeeDocument, options);
      }
export type UpdateUserEmployeeMutationHookResult = ReturnType<typeof useUpdateUserEmployeeMutation>;
export type UpdateUserEmployeeMutationResult = Apollo.MutationResult<UpdateUserEmployeeMutation>;
export type UpdateUserEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateUserEmployeeMutation, UpdateUserEmployeeMutationVariables>;