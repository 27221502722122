import gql from "graphql-tag";
import determinePrimaryColor from "../functions/determinePrimaryColor";
export const GET_NOTIFICATION = gql`
  query GET_NOTIFICATION($user_id: uuid!) {
    notification(
      where: { user_id: { _eq: $user_id }, _and: { read: { _eq: false } } }
      limit: 10
      order_by: { created_at: desc }
    ) {
      link
      message
      type
      read
      user_id
      id
    }
    notification_aggregate(
      where: { _and: { read: { _eq: false }, user_id: { _eq: $user_id } } }
    ) {
      aggregate {
        count(columns: message)
      }
    }
  }
`;

export const GET_ALL_NOTIFICATIONS_COUNT = gql`
  subscription GET_NOTIFICATION_COUNT($user_id: uuid!) {
    notification_aggregate(
      where: { _and: { read: { _eq: false }, user_id: { _eq: $user_id } } }
    ) {
      aggregate {
        count(columns: message)
      }
    }
  }
  `

// export const GET_NOTIFICATION_COUNT = gql`
//   query GET_NOTIFICATION_COUNT($user_id: uuid!) {
//     notification_aggregate(
//       where: { _and: { read: { _eq: false }, user_id: { _eq: $user_id } } }
//     ) {
//       aggregate {
//         count(columns: message)
//       }
//     }
//   }
// `;

export const UPDATE_NOTIFICATION = gql`
  mutation UPDATE_NOTIFICATION($id: Int!) {
    update_notification_by_pk(pk_columns: { id: $id }, _set: { read: true }) {
      id
    }
  }
`;

// export const READ_ALL_NOTIFICATIONS = gql`
//   mutation READ_ALL_NOTIFICATIONS($user_id: uuid!) {
//     update_notification(
//       where: { user_id: { _eq: $user_id }, read: { _eq: false } }
//       _set: { read: true }
//     ) {
//       returning {
//         user_id
//       }
//     }
//   }
// `;
