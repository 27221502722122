export type User = typeof users[number];

export const users = [
    {
      id: "'9b73e2a3-4582-42fb-8a59-49c57d40b071'",
      role: "'hulpverlener'",
      email: "'z.ercan@curess.nl'",
      name: "Zeynep Ercan | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'66826d1b-0b51-410e-8e93-9460bb128f0e'",
      role: "'hulpverlener'",
      email: "'m.kempees@curess.nl'",
      name: "'Mitchell Kempees | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2f9858af-a870-43be-b295-f0d4ea5af39e'",
      role: "'hulpverlener'",
      email: "'l.schilderinck@curess.nl'",
      name: "'Lindy Schilderinck | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.36",
      ons_employee_identifier: "360",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c81411c3-fe34-4701-b6dd-dc86d031b583'",
      role: "'hulpverlener'",
      email: "'l.waaijer@curess.nl'",
      name: "'Lisa Waaijer | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'7362f10a-2d58-401f-a697-b9b62884de8d'",
      role: "'hulpverlener'",
      email: "'d.mik@curess.nl'",
      name: "'Danielle Mik | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3c2f67ff-343f-443c-9f73-2aea4fc8540b'",
      role: "'hulpverlener'",
      email: "'s.wedzinga@curess.nl'",
      name: "'Sanne Wedzinga | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d3c6fb3b-3b80-4424-b11d-ccedd0750955'",
      role: "'hulpverlener'",
      email: "'L.vangennep@curess.nl'",
      name: "'Leonie van Gennep | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ed922918-ffb4-4113-8531-d40598e26d64'",
      role: "'hulpverlener'",
      email: "'j.roos@curess.nl'",
      name: "'Julia Roos | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0461d8a4-4bdf-4436-a1e2-c985ce7e2cd9'",
      role: "'hulpverlener'",
      email: "'d.beljaars@curess.nl'",
      name: "'Demi Beljaars | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.41",
      ons_employee_identifier: "359",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f569d3f3-8a63-4752-b5a4-c4645069381c'",
      role: "'hulpverlener'",
      email: "'r.hemel@curess.nl'",
      name: "'Rachel Hemel | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ad3d546c-f0d1-4ad7-badf-6d0cfe914e15'",
      role: "'hulpverlener'",
      email: "'l.spit@curess.nl'",
      name: "'Lisanne Spit | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'fa1e4975-272a-4d4e-98a3-e6638da0b39e'",
      role: "'hulpverlener'",
      email: "'l.smit@curess.nl'",
      name: "'Lisette Smit | Curess'",
      active: "FALSE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8d30fbba-e9c4-4677-a646-302168532933'",
      role: "'hulpverlener'",
      email: "'m.berkhof@curess.nl'",
      name: "'Michel Berkhof | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.47",
      ons_employee_identifier: "56842",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f3f4b24b-5ce7-478f-89c3-ac30ae40caff'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 4'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ed09847a-ef2c-4226-9e68-5b3bee3ec803'",
      role: "'hulpverlener'",
      email: "'m.durmusoglu@curess.nl'",
      name: "'Melike Durmusoglu | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.13",
      ons_employee_identifier: "272",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b5400a18-a1e8-4437-8973-18241d29c35a'",
      role: "'hulpverlener'",
      email: "'s.steentjes@curess.nl'",
      name: "'Sanne Steentjes | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'cdcfcb33-c5d1-4418-acf6-766b286ffa59'",
      role: "'hulpverlener'",
      email: "'l.terhorst@curess.nl'",
      name: "'Loes ter Horst | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b2b9b987-f10e-4036-b858-39a43ce4ac03'",
      role: "'hulpverlener'",
      email: "'l.alink@curess.nl'",
      name: "'Lisanne Alink | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.09",
      ons_employee_identifier: "323",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d0bc085e-cb6d-4066-a8c8-31f99fd53456'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 8'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'bf02e598-8bcb-4231-8856-d4c4bdc93041'",
      role: "'hulpverlener'",
      email: "'c.draaisma@curess.nl'",
      name: "'Carein  Draaisma | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b3bb4064-e8f0-4e38-82fd-33edbb12a810'",
      role: "'hulpverlener'",
      email: "'r.schut@curess.nl'",
      name: "'Rob Schut | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.18",
      ons_employee_identifier: "352",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'871f295b-8572-47ce-88bd-b63067f5f9d4'",
      role: "'hulpverlener'",
      email: "'b.lenferink@curess.nl'",
      name: "'Britt  Lenferink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8c6dd885-a443-4e6d-a664-f625cc424e93'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 3'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b5995609-6945-4549-8938-4a7925947f27'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 10'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0d684d87-f947-42eb-84e2-76a13fe1f8b6'",
      role: "'hulpverlener'",
      email: "'s.dolphijn@curess.nl'",
      name: "'Sandra Dolphijn | Curess'",
      active: "TRUE",
      ons_location_identifier: "15.3",
      ons_employee_identifier: "345",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'fa53ccc0-3bf5-4fde-ae61-02aca733b78b'",
      role: "'hulpverlener'",
      email: "'m.stegeman@curess.nl'",
      name: "'Marlon Stegeman | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.31",
      ons_employee_identifier: "343",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1f852422-601c-11ec-8607-0242ac130002'",
      role: "'team_coordinator'",
      email: "'coordinator@profitflow.nl'",
      name: "'Cor Dinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'60b0fc76-d1f1-44fa-9413-f3cf9717e99a'",
      role: "'planner'",
      email: "'d.wieringa@curess.nl'",
      name: "'Denise  Wieringa |  Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0c2d56a8-082e-4d41-ad57-857fb1ce0632'",
      role: "'hulpverlener'",
      email: "'l.kosterink@curess.nl'",
      name: "'Lynn Kosterink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'51636660-4ac8-4048-a6db-07aadbe33495'",
      role: "'hulpverlener'",
      email: "'y.tang@curess.nl'",
      name: "'Ying-Lien Tang | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ba98f832-fd5c-4866-9df1-71e606d6eee9'",
      role: "'hulpverlener'",
      email: "'l.vinke@curess.nl'",
      name: "'Leonie Vinke | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4a58200a-2a3a-4295-a104-4e2419f25aa0'",
      role: "'hulpverlener'",
      email: "'c.lammerdink@curess.nl'",
      name: "'Corine Lammerdink | Curess'",
      active: "FALSE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1aec818d-b608-42bb-a192-5d61427cc137'",
      role: "'hulpverlener'",
      email: "'e.sari@curess.nl'",
      name: "'Elif Sari | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.24",
      ons_employee_identifier: "389",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8858c992-eec6-4486-8fb3-e56126862e44'",
      role: "'hulpverlener'",
      email: "'s.sahin@curess.nl'",
      name: "'S√ºmeyye Sahin | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'25dc3fbe-cea6-4638-a962-af25b74bda1f'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team ISB'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f85db343-7e8c-4397-8f7c-c2c97005363c'",
      role: "'hulpverlener'",
      email: "'f.peulken@curess.nl'",
      name: "'Fabienne Peulken | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2b818668-ce1e-488b-b900-1082d0e99b7a'",
      role: "'hulpverlener'",
      email: "'b.sama@curess.nl'",
      name: "Beyda Sama | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.48",
      ons_employee_identifier: "385",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'414daf3a-c403-4035-93f3-1969d564d128'",
      role: "'hulpverlener'",
      email: "'D.hofmeijer@curess.nl'",
      name: "'Daphne Hofmeijer |  Curess'",
      active: "TRUE",
      ons_location_identifier: "5.15",
      ons_employee_identifier: "169",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ff509bba-8700-428b-8124-5a7ac93cf906'",
      role: "'hulpverlener'",
      email: "'m.caarels@curess.nl'",
      name: "'Manon Caarels | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3138bce7-137d-4854-9bc7-cf045ff56d16'",
      role: "'hulpverlener'",
      email: "'f.tedjo@curess.nl'",
      name: "'Felita Tedjo | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'27efce0c-c1f3-4e59-a38a-be036a3ddb2e'",
      role: "'hulpverlener'",
      email: "'e.mulder@curess.nl'",
      name: "'Eline Mulder | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.32",
      ons_employee_identifier: "132",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4e5fbfb9-f9d3-48d5-83ca-64fab2e2afc7'",
      role: "'hulpverlener'",
      email: "'m.alberts@curess.nl'",
      name: "'Marloes Alberts | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0e236720-196f-42fb-aa9c-3fe5ea1dac42'",
      role: "'hulpverlener'",
      email: "'e.enoch@curess.nl'",
      name: "'Emmely Enoch | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ffec2e5a-6471-4812-858f-be0f1b2a61e9'",
      role: "'hulpverlener'",
      email: "'k.kolner@curess.nl'",
      name: "'Kim Kolner | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d814d671-c624-464c-8f17-aa2c58a83744'",
      role: "'hulpverlener'",
      email: "'j.ensink@curess.nl'",
      name: "'Jo√´lle Ensink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'e1e87d0e-9404-416d-baa4-870f6535f108'",
      role: "'hulpverlener'",
      email: "'r.bolk@curess.nl'",
      name: "'Rick Bolk | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'6c362d1f-c92c-4288-b71f-fe3736ad3061'",
      role: "'hulpverlener'",
      email: "'m.scheffer@curess.nl'",
      name: "'Myrthe Scheffer | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.09",
      ons_employee_identifier: "256",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'76411b9d-d479-4f1a-85ca-e8e324804d44'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 11'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f59e77e3-f880-4f5a-bc44-68273887a060'",
      role: "'planner'",
      email: "'profitflow@curess.nl'",
      name: "'ProfitFlow'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'59b9fd82-8919-4c1a-96f9-21c4f4b0cb5b'",
      role: "'planner'",
      email: "'m.koning@curess.nl'",
      name: "'Mark Koning | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f7b977c5-df7b-48d8-b8e1-30046074264b'",
      role: "'hulpverlener'",
      email: "'m.eberhard@curess.nl'",
      name: "'Moniek Eberhard | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b8ad12b4-4dc5-470a-af8e-ce3c20e3846c'",
      role: "'hulpverlener'",
      email: "'a.zwiers@curess.nl'",
      name: "Amy Zwiers | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f5df66cf-42eb-4be8-acd8-a3e3ee338d8e'",
      role: "'hulpverlener'",
      email: "'t.weijers@curess.nl'",
      name: "'Tessa Weijers | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'34d84988-6c87-4629-b370-a81c6963fe4d'",
      role: "'hulpverlener'",
      email: "'m.hoek@curess.nl'",
      name: "'Manuela Hoek | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'bfcda380-d53f-423c-bbfc-f5709f3d942d'",
      role: "'hulpverlener'",
      email: "'m.gallas@curess.nl'",
      name: "'Mascha Gallas | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'226153ea-d877-403f-bebb-6953e894e99c'",
      role: "'team_coordinator'",
      email: "'j.wevers@curess.nl'",
      name: "'Jeroen Wevers | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d874f4bd-3ba9-4130-96a1-785d3b408ea8'",
      role: "'hulpverlener'",
      email: "'a.leferink@curess.nl'",
      name: "'Asena Leferink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ac3e8bcc-81b8-42d8-aca1-fb4578efa66b'",
      role: "'team_coordinator'",
      email: "'d.heppen@curess.nl'",
      name: "'Dennis Heppen | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'751a1195-64f9-4004-94a2-430042310603'",
      role: "'hulpverlener'",
      email: "'l.tenhagen@curess.nl'",
      name: "'Lotte Tenhagen | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.12",
      ons_employee_identifier: "213",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'fcac863d-88e4-4721-9fc2-bcf6ab086bb8'",
      role: "'hulpverlener'",
      email: "'d.conijn@curess.nl'",
      name: "'Dani√´lle Conijn | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a15d84df-76b9-40a9-ac32-5c990e870513'",
      role: "'hulpverlener'",
      email: "'m.koers@curess.nl'",
      name: "'Myrna Koers | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.26",
      ons_employee_identifier: "32",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'e7ed21b2-c305-46e8-a386-1c63431189e4'",
      role: "'hulpverlener'",
      email: "'k.lenferink@curess.nl'",
      name: "'Karlijn Lenferink | Curess'",
      active: "TRUE",
      ons_location_identifier: "12.2",
      ons_employee_identifier: "25",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'06ef9350-323f-418a-a80b-f3f0b369ab86'",
      role: "'hulpverlener'",
      email: "'l.kutlubay@curess.nl'",
      name: "'Lorin Kutlubay | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.24",
      ons_employee_identifier: "397",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b6fbe2d3-98d6-4d61-ba00-eca06d28d2df'",
      role: "'hulpverlener'",
      email: "'i.jansen@curess.nl'",
      name: "'Ilse Jansen | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.27",
      ons_employee_identifier: "363",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'57ecab40-de79-4080-baa9-168a559ad117'",
      role: "'hulpverlener'",
      email: "'m.hek@curess.nl'",
      name: "'Monique Hek | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'754a3d43-c2ab-4ff4-86c0-23d55f07fa5d'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Profitflow Hulpverlener'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'776d18cb-aa37-4462-85a2-bf21606be411'",
      role: "'hulpverlener'",
      email: "'d.alfons@curess.nl'",
      name: "'Daphne Alfons | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'59af5b97-0116-4de6-89c9-14841f02a970'",
      role: "'hulpverlener'",
      email: "'m.pouw@curess.nl'",
      name: "'Minke Pouw | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.25",
      ons_employee_identifier: "392",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3060fd17-2574-41af-9fee-c3b9b9cd02fb'",
      role: "'hulpverlener'",
      email: "'a.brink@curess.nl'",
      name: "'Anouk Brink | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.38",
      ons_employee_identifier: "399",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a5610a3e-7cc3-443b-a29f-32f1c1b449aa'",
      role: "'hulpverlener'",
      email: "'s.kamp@curess.nl'",
      name: "'Sjerai Kamp | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.30",
      ons_employee_identifier: "837",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'6ecf0fd7-0320-4fd8-a6d5-914f659baf08'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Profitflow Teamco√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'57f16ec4-f6cb-4720-b6d2-f14f35d690c3'",
      role: "'hulpverlener'",
      email: "'m.heijink@curess.nl'",
      name: "'Martine Heijink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'000533f1-d10e-4281-8778-ee60b6a60046'",
      role: "'hulpverlener'",
      email: "'s.sarabia@curess.nl'",
      name: "'Shumairis Sarabia | Curess'",
      active: "FALSE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'449e5317-9d8a-4a23-840d-e7cacc11ce1e'",
      role: "'hulpverlener'",
      email: "'m.smit@curess.nl'",
      name: "'Marco Smit | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.42",
      ons_employee_identifier: "378",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ae823dfc-02c3-4894-8932-f83afa28989c'",
      role: "'hulpverlener'",
      email: "'l.tielbeek@curess.nl'",
      name: "'Lieke Tielbeek | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4b3512cc-7a12-4a19-a1d8-450924faf360'",
      role: "'team_coordinator'",
      email: "'n.hillebrandt@curess.nl'",
      name: "'Nicole Hillebrandt | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c1cb8969-7f42-48cd-adc3-2c2f3e16124d'",
      role: "'hulpverlener'",
      email: "'j.meulenbeek@curess.nl'",
      name: "'Jaap Meulenbeek | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.06",
      ons_employee_identifier: "232",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c1e72368-d387-4eea-8792-76d3250e1651'",
      role: "'hulpverlener'",
      email: "'f.vandersterren@curess.nl'",
      name: "'Floor van der Sterren | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.15",
      ons_employee_identifier: "62",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'42da2ff1-a245-4a18-bafe-0ca2f4216230'",
      role: "'hulpverlener'",
      email: "'m.ernsten@curess.nl'",
      name: "'Marij Ernsten | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.27",
      ons_employee_identifier: "521584",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'84f9ab7a-c637-451e-bfb2-3cd2bcf17ce7'",
      role: "'hulpverlener'",
      email: "'m.davidis@curess.nl'",
      name: "'Merle Davidis | Curess'",
      active: "FALSE",
      ons_location_identifier: "10.09",
      ons_employee_identifier: "92",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'07623137-8a99-48e5-b552-49a4fc1c3b58'",
      role: "'hulpverlener'",
      email: "'m.kleingunnewiek@curess.nl'",
      name: "'Marijn Klein Gunnewiek | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.22",
      ons_employee_identifier: "289",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f9b38e7d-9c00-4d6d-bb95-8d809f3d8d08'",
      role: "'hulpverlener'",
      email: "'j.willems@curess.nl'",
      name: "'Jari Willems | Curess'",
      active: "TRUE",
      ons_location_identifier: "15.11",
      ons_employee_identifier: "521021",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'e19f2aa0-d931-4be6-8448-c8fa608c469e'",
      role: "'hulpverlener'",
      email: "'w.beeftink@curess.nl'",
      name: "'Wendy  Beeftink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c079b4ef-db7d-4973-910a-8d274904e489'",
      role: "'hulpverlener'",
      email: "'m.jansen@curess.nl'",
      name: "'Melanie Jansen | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.17",
      ons_employee_identifier: "337",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'061fb3f1-dd1c-48b9-bcfd-6b6dc95a9a8c'",
      role: "'hulpverlener'",
      email: "'k.hendriks@curess.nl'",
      name: "'Kyra  Hendriks | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d6b21c00-0d15-4e82-bb2a-f40d45d54d79'",
      role: "'hulpverlener'",
      email: "'b.poppe@curess.nl'",
      name: "'Bram Poppe | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.51",
      ons_employee_identifier: "5214569",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'87485fec-f343-4d8e-ad4d-78524db2818c'",
      role: "'hulpverlener'",
      email: "'j.hoekman@curess.nl'",
      name: "'Jill Hoekman | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.11",
      ons_employee_identifier: "75",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0be3576a-6276-4401-9be9-175d186f773f'",
      role: "'hulpverlener'",
      email: "'n.welmers@curess.nl'",
      name: "'Nicole Welmers | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a41acf85-21da-4d01-afef-a222b34e8bc2'",
      role: "'hulpverlener'",
      email: "'d.alan@curess.nl'",
      name: "'Diana Alan | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'367baaa0-63f5-4b32-8278-9acd9966b186'",
      role: "'hulpverlener'",
      email: "'a.kamphuis@curess.nl'",
      name: "'Amber Kamphuis | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.07",
      ons_employee_identifier: "99",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'41b3aad8-f8c3-4b43-a4ef-47bdcc07466c'",
      role: "'hulpverlener'",
      email: "'e.kunst@curess.nl'",
      name: "'Elles  Kunst |  Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8c80268a-6036-42d4-a3d2-c3699955e31e'",
      role: "'hulpverlener'",
      email: "'R.vitadamo@curess.nl'",
      name: "'Romy Vitadamo | Curess'",
      active: "FALSE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'be76be40-025c-4420-b095-706dd1327ca9'",
      role: "'hulpverlener'",
      email: "'p.loohuis@curess.nl'",
      name: "'Pien Loohuis | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.04",
      ons_employee_identifier: "101",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5d545075-bed3-4ead-b7c9-354263180b19'",
      role: "'hulpverlener'",
      email: "'m.hubers@curess.nl'",
      name: "'Marc Hubers | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.36",
      ons_employee_identifier: "277",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3793180d-2f58-4000-91d1-1e3b9fa26c03'",
      role: "'hulpverlener'",
      email: "'a.trip@curess.nl'",
      name: "'Anouk Trip | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.05",
      ons_employee_identifier: "200",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'7feca12e-6c84-433f-95a6-3370232f8965'",
      role: "'hulpverlener'",
      email: "'i.hendriks@curess.nl'",
      name: "'Inge Hendriks | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.28",
      ons_employee_identifier: "56287",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b35c3d6d-8192-45dd-80a0-d5d6da9b633f'",
      role: "'hulpverlener'",
      email: "'n.colvoort@curess.nl'",
      name: "'Nynke Colvoort | Curess'",
      active: "TRUE",
      ons_location_identifier: "15.12",
      ons_employee_identifier: "52142",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8e14112e-cb9a-4a37-9636-71725db2035a'",
      role: "'hulpverlener'",
      email: "'m.vanbuuren@curess.nl'",
      name: "'Marita van Buuren | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1f0835c7-7269-487c-ab7b-613b6b8cf1c1'",
      role: "'hulpverlener'",
      email: "'m.christenhusz@curess.nl'",
      name: "'Milou Christenhusz | Curess'",
      active: "FALSE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'05d0a9f1-19f0-48ae-af88-1aa4561a3675'",
      role: "'hulpverlener'",
      email: "'i.grunder@curess.nl'",
      name: "'Inge Grunder | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.08",
      ons_employee_identifier: "321",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9f508a2c-35ea-4ae9-aca4-f7e59b30290e'",
      role: "'hulpverlener'",
      email: "'i.tewierik@curess.nl'",
      name: "'Isabelle te Wierik | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'53fd8f0b-fb4d-4540-a08b-14e8e2b223f0'",
      role: "'hulpverlener'",
      email: "'m.pluimers@curess.nl'",
      name: "'Marieke Pluimers | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0ebb52aa-c45e-478a-945f-72710729a710'",
      role: "'hulpverlener'",
      email: "'s.jenneboer@curess.nl'",
      name: "'Sanne Jenneboer | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.25",
      ons_employee_identifier: "88",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'7da5d7c2-5e1b-41e2-84db-afa0b739e9a1'",
      role: "'hulpverlener'",
      email: "'j.terhoek@curess.nl'",
      name: "'Janine ter Hoek | Curess'",
      active: "TRUE",
      ons_location_identifier: "12.4",
      ons_employee_identifier: "71",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4255efb0-6702-43a4-bc71-90f309e016ab'",
      role: "'hulpverlener'",
      email: "'p.stolk@curess.nl'",
      name: "'Paula Stolk | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.35",
      ons_employee_identifier: "316",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4fbe8576-3b32-45f9-990e-fb98b5a068e3'",
      role: "'team_coordinator'",
      email: "'a.elkink@curess.nl'",
      name: "Annabel Elkink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a5c9b913-4391-46e4-9b22-860c232c6aa7'",
      role: "'hulpverlener'",
      email: "'l.berendsen@curess.nl'",
      name: "'Laura  Berendsen | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.16",
      ons_employee_identifier: "252",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'20d6a77e-8b60-4b66-9af6-59e008ae1843'",
      role: "'hulpverlener'",
      email: "'c.galenkamp@curess.nl'",
      name: "'Carlijn Galenkamp | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d38705f0-890f-4a4f-acf9-5dd17eaffeaf'",
      role: "'hulpverlener'",
      email: "'a.gelevert@curess.nl'",
      name: "'Amber Gelevert | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.18",
      ons_employee_identifier: "287",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c64ae01c-49cd-45b9-b671-95f6d20688ba'",
      role: "'hulpverlener'",
      email: "'c.vanzwol@curess.nl'",
      name: "'Carlijn van Zwol | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.16",
      ons_employee_identifier: "302",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'6dc310b9-c812-45e9-b882-f85cb4bc6f4d'",
      role: "'hulpverlener'",
      email: "'m.wijnreder@curess.nl'",
      name: "'Mark Wijnreder | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.23",
      ons_employee_identifier: "824",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f67353db-8847-4a7e-bdca-40585eae4737'",
      role: "'hulpverlener'",
      email: "'m.bijsterbosch@curess.nl'",
      name: "'Myrthe Bijsterbosch | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0892c2d7-7388-4360-a180-6c5dfde17a09'",
      role: "'hulpverlener'",
      email: "'c.menkhorst@curess.nl'",
      name: "'Corien Menkhorst | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.41",
      ons_employee_identifier: "347",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'bbb7105f-4f27-4a12-8cad-b5ced3e52530'",
      role: "'hulpverlener'",
      email: "'a.vendel@curess.nl'",
      name: "'Ariane Vendel | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.45",
      ons_employee_identifier: "835",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'77966f31-5abf-4d18-a371-27b05a6ceb2b'",
      role: "'hulpverlener'",
      email: "'l.gerrits@curess.nl'",
      name: "'Lisette Gerrits | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.31",
      ons_employee_identifier: "65854",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2e05c74d-912d-487b-a2a7-1ebf4e5abb70'",
      role: "'hulpverlener'",
      email: "'m.wind@curess.nl'",
      name: "'Marloes Wind | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0d2181c8-e12c-4064-95a9-699f11425465'",
      role: "'hulpverlener'",
      email: "'w.knol@curess.nl'",
      name: "'Wicher Knol | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a25a398c-6b4f-42ac-9a6f-d03ffc2872a3'",
      role: "'hulpverlener'",
      email: "'m.siebring@curess.nl'",
      name: "'Manon Siebring | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.12",
      ons_employee_identifier: "308",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3ef86662-2de7-464b-b29f-c43cf21da158'",
      role: "'hulpverlener'",
      email: "'r.pluimers@curess.nl'",
      name: "'Ren√©e Pluimers | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'dd482762-b07c-4bf4-a293-c00d5413a06c'",
      role: "'hulpverlener'",
      email: "'m.benjamins@curess.nl'",
      name: "'Mariska  Benjamins | Curess'",
      active: "FALSE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2fba3247-c30c-4bfe-abe9-97bd8bbb89ef'",
      role: "'hulpverlener'",
      email: "'c.vanderveen@curess.nl'",
      name: "'Coen van der Veen | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a0f8a5d3-55b9-410a-8b64-cf1749ff3ca2'",
      role: "'team_coordinator'",
      email: "'j.schorn@curess.nl'",
      name: "'Joeri Schorn | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'05527b99-d903-4338-b806-36917ec76b29'",
      role: "'hulpverlener'",
      email: "'n.vanderlende@curess.nl'",
      name: "'Niels van der Lende | Curess'",
      active: "TRUE",
      ons_location_identifier: "15.2",
      ons_employee_identifier: "271",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'bbc6f9ba-e8ff-4441-b5ee-0d0ce3482ce9'",
      role: "'team_coordinator'",
      email: "'n.aanstoot@curess.nl'",
      name: "'Nicole Aanstoot | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1aa8b9ba-fc34-45e0-bbd8-fa2aeea91cfa'",
      role: "'hulpverlener'",
      email: "'c.meijerink@curess.nl'",
      name: "'Christy Meijerink | Curess'",
      active: "FALSE",
      ons_location_identifier: "3.05",
      ons_employee_identifier: "103",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1e53e344-97c8-4d30-a6d7-ffd812752dcd'",
      role: "'hulpverlener'",
      email: "'m.kolkman@curess.nl'",
      name: "'Merel Kolkman | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.34",
      ons_employee_identifier: "269",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c7bfd167-648a-4869-8c7a-fe6ec2dcaa0d'",
      role: "'hulpverlener'",
      email: "'c.vanmaren@curess.nl'",
      name: "'Carolien van Maren | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.16",
      ons_employee_identifier: "144",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'72bdd2d9-fa1a-40a6-822e-8156ec7546cc'",
      role: "'hulpverlener'",
      email: "'m.doeschot@curess.nl'",
      name: "'Manon Doeschot | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ecc01801-3e42-410d-a1ac-61b9d20157b4'",
      role: "'hulpverlener'",
      email: "'r.devries@curess.nl'",
      name: "'Rob de Vries |  Curess'",
      active: "TRUE",
      ons_location_identifier: "11.07",
      ons_employee_identifier: "201",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'de88ac04-215c-4630-be60-68545b184e26'",
      role: "'team_coordinator'",
      email: "'s.huegen@curess.nl'",
      name: "'Sjors Huegen | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'14504458-dc7d-4569-8e9f-131169f3c5a8'",
      role: "'team_coordinator'",
      email: "'j.vandervegt@curess.nl'",
      name: "'Joy van der Vegt | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5100f899-fb1f-41f7-b73e-d8e7ea0b2894'",
      role: "'team_coordinator'",
      email: "'a.enkelaar@curess.nl'",
      name: "'Annelies Enkelaar | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'54ecfd5a-0f7f-45ac-b334-4d2062f28365'",
      role: "'hulpverlener'",
      email: "'m.speijers@curess.nl'",
      name: "'Marije Speijers | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.38",
      ons_employee_identifier: "340",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9b36d3a0-f7fa-4040-a3b8-39c253adf73f'",
      role: "'hulpverlener'",
      email: "'e.heetkamp@curess.nl'",
      name: "'Eva Heetkamp | Curess'",
      active: "TRUE",
      ons_location_identifier: "12.3",
      ons_employee_identifier: "205",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'fec1442c-2462-40f5-826d-bbf17f407690'",
      role: "'hulpverlener'",
      email: "'d.thijsen@curess.nl'",
      name: "'Dehlayla Thijsen | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9d58a369-4cfd-44c2-ae0d-2bc2c08775d2'",
      role: "'hulpverlener'",
      email: "'p.ebben@curess.nl'",
      name: "'Peter Ebben | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.43",
      ons_employee_identifier: "365",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'61688583-98fe-46d9-b333-533453473e53'",
      role: "'hulpverlener'",
      email: "'d.kobes@curess.nl'",
      name: "'Denise  Kobes | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3c63a5a3-3f48-45da-b081-161569f3d714'",
      role: "'hulpverlener'",
      email: "'b.dejong@curess.nl'",
      name: "'Bas de Jong | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.39",
      ons_employee_identifier: "341",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'bbe6f88d-101b-4e51-a529-328ac2f4bd2f'",
      role: "'hulpverlener'",
      email: "'s.taal@curess.nl'",
      name: "'Stefan Taal | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.19",
      ons_employee_identifier: "195",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'e7bdfef6-45c9-42ad-9e89-ad2ceb04f9cf'",
      role: "'hulpverlener'",
      email: "'k.tankink@curess.nl'",
      name: "'Kim Tankink | Curess'",
      active: "FALSE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3ec64dfd-18c6-4421-ae4d-c137563f5dd1'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 5'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1b69f04f-00b9-4e36-9219-0adc1aaa15e9'",
      role: "'hulpverlener'",
      email: "'N.kortstee@curess.nl'",
      name: "'Nicky Kortstee | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.02",
      ons_employee_identifier: "155",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9fdca91d-dcd8-4d91-9fd0-73d0ee78fa61'",
      role: "'hulpverlener'",
      email: "'r.peters@curess.nl'",
      name: "'Rianne  Peters | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'479b1171-8e0b-41cc-80dc-d16b2695f0f2'",
      role: "'hulpverlener'",
      email: "'r.schreur@curess.nl'",
      name: "'Romee Schreur | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.22",
      ons_employee_identifier: "348",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'324c64bf-5c29-414c-a628-021550d58552'",
      role: "'hulpverlener'",
      email: "'a.vrijkotte@curess.nl'",
      name: "'Anouk Vrijkotte | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.17",
      ons_employee_identifier: "334",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'40f1d7cd-bb19-44e9-a70b-5b77102c187c'",
      role: "'hulpverlener'",
      email: "'j.tergrote@curess.nl'",
      name: "'Jorien ter Grote | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.29",
      ons_employee_identifier: "301",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9e161c54-ddf4-4725-bc83-7d2078d0bfe6'",
      role: "'hulpverlener'",
      email: "'l.moraal@curess.nl'",
      name: "'Laura Moraal | Curess'",
      active: "TRUE",
      ons_location_identifier: "12.8",
      ons_employee_identifier: "368",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'67c673e7-c9b5-4a93-840a-10601d723a4e'",
      role: "'hulpverlener'",
      email: "'j.bruggink@curess.nl'",
      name: "'Julia Bruggink | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.43",
      ons_employee_identifier: "5612362",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0ad624e9-ea5b-4ec2-ba8a-808919f2c071'",
      role: "'hulpverlener'",
      email: "'h.ruiter@curess.nl'",
      name: "'Hilde Ruiter | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.29",
      ons_employee_identifier: "317",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'45ffe501-dfde-491d-9a8a-622769c3abcc'",
      role: "'hulpverlener'",
      email: "'b.sjerps@curess.nl'",
      name: "'Barbara Sjerps | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.46",
      ons_employee_identifier: "839",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'25092eff-3f3b-4127-8b8f-88549b585600'",
      role: "'hulpverlener'",
      email: "'w.groothuis@curess.nl'",
      name: "'Wieke  Groothuis | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'87564bf1-8765-4feb-98b8-4f8dfaca66c2'",
      role: "'hulpverlener'",
      email: "'k.jansen@curess.nl'",
      name: "'Kay Jansen | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.22",
      ons_employee_identifier: "374",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'259c16a7-a101-4a63-8d37-ded466e05c51'",
      role: "'hulpverlener'",
      email: "'d.debie@curess.nl'",
      name: "'Demi de Bie | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b4ff8730-19e2-457f-a6ee-7ee54f4911dd'",
      role: "'hulpverlener'",
      email: "'e.bekker@curess.nl'",
      name: "'Emma Bekker | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'36754dd4-40dd-416b-9f55-62dac0987493'",
      role: "'hulpverlener'",
      email: "'l.letteboer@curess.nl'",
      name: "'Laura Letteboer | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d051601d-d0c6-4e22-b74b-fb98eb6eb456'",
      role: "'hulpverlener'",
      email: "'L.dijkhuis@curess.nl'",
      name: "'Loes Dijkhuis | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.03",
      ons_employee_identifier: "156",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4703d167-e4db-4355-8023-a69f5e4c0278'",
      role: "'hulpverlener'",
      email: "'g.veurink@curess.nl'",
      name: "'Giselle Veurink | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.33",
      ons_employee_identifier: "344",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9a495032-6d61-476e-a5df-cfcff4c37559'",
      role: "'hulpverlener'",
      email: "'j.munning@curess.nl'",
      name: "'Jorien Munning | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.19",
      ons_employee_identifier: "346",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'81662d5f-6f9f-4015-9846-24ac18fba41a'",
      role: "'hulpverlener'",
      email: "'b.ridderbos@curess.nl'",
      name: "'Bjorn Ridderbos | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'efa2c097-043e-4bf7-ac14-f9a5903de8a1'",
      role: "'hulpverlener'",
      email: "'j.pieters@curess.nl'",
      name: "'Jurjen Pieters | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.25",
      ons_employee_identifier: "358",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a59f4228-510b-49b2-b051-938e54dd5876'",
      role: "'hulpverlener'",
      email: "'n.vugteveen@curess.nl'",
      name: "'Nadien Vugteveen | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.21",
      ons_employee_identifier: "254",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9ede8cd8-cbec-49a4-9c11-401bb123bff4'",
      role: "'hulpverlener'",
      email: "'a.zuidhof@curess.nl'",
      name: "'Angelique Zuidhof | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.06",
      ons_employee_identifier: "309",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3b57b93d-2355-4c91-9de2-cf588219e7c6'",
      role: "'hulpverlener'",
      email: "'m.vanmunster@curess.nl'",
      name: "'Manon van Munster | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.03",
      ons_employee_identifier: "76",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ec3f7e40-bb25-45bc-9cf4-d80786fb106a'",
      role: "'hulpverlener'",
      email: "'n.thijssen@curess.nl'",
      name: "'Naomi Thijssen | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.26",
      ons_employee_identifier: "376",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'7ce5b16d-633f-4801-a79c-04bc07808177'",
      role: "'hulpverlener'",
      email: "'t.pauls@curess.nl'",
      name: "'Terry Pauls | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c07eef38-49da-4476-beb1-6df833490e7c'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Anouk Middelink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'504c0c4f-e66b-4d26-b1a7-205ff9467af6'",
      role: "'hulpverlener'",
      email: "'m.cildavil@curess.nl'",
      name: "'Melissa Cildavil | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.34",
      ons_employee_identifier: "349",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0d7b9cd0-2c63-4d34-8768-e6bfa5d2895a'",
      role: "'hulpverlener'",
      email: "'m.nijland@curess.nl'",
      name: "'Mari√´lle Nijland | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.05",
      ons_employee_identifier: "257",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'e53d4043-1a2f-44aa-8ab3-a55f0ea68c93'",
      role: "'hulpverlener'",
      email: "'m.devries@curess.nl'",
      name: "'Milou de Vries | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'728464a4-2217-4d83-a689-ffbeff462159'",
      role: "'team_coordinator'",
      email: "'k.meuldijk@curess.nl'",
      name: "'Karlin Meuldijk | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3b8b55a2-4e02-407a-a8e5-296b23c8f4d0'",
      role: "'team_coordinator'",
      email: "'i.notkamp@curess.nl'",
      name: "'Ilse Notkamp | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a40449c2-e474-48cd-b8aa-a2e6468e289a'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 9 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'fec7f7f1-81ce-45e9-b0f9-90680f69aff9'",
      role: "'read_only'",
      email: "'s.wilharms@curess.nl'",
      name: "'Sanne Wilharms | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c9d45957-e0c9-4a81-ad57-d86956b67fa0'",
      role: "'read_only'",
      email: "null",
      name: "'A Architechs | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4bb44e78-06ee-44ac-bf35-3cd0635933d9'",
      role: "'hulpverlener'",
      email: "'l.eijsink@curess.nl'",
      name: "'Linde Eijsink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'be5365f8-b16b-41e4-a762-416bd6cf75b6'",
      role: "'hulpverlener'",
      email: "'b.yasar@curess.nl'",
      name: "'Berin Yasar | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.39",
      ons_employee_identifier: "320",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'65924f04-0f32-442d-9887-d32abf2b8710'",
      role: "'hulpverlener'",
      email: "'l.dewit@curess.nl'",
      name: "'Lieke de Wit | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.30",
      ons_employee_identifier: "243",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0f4a3b4b-5f2d-4824-b6ef-3b602e302388'",
      role: "'hulpverlener'",
      email: "'z.vanleeuwen@curess.nl'",
      name: "'Zo√´ Van Leeuwen | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9224b961-d7f6-4d72-a5c0-c121176b14ed'",
      role: "'hulpverlener'",
      email: "'a.tenthije@curess.nl'",
      name: "'Anne ten Thije | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b4c714d7-6b19-443c-b16a-c05c8caa86fb'",
      role: "'hulpverlener'",
      email: "'e.hofste@curess.nl'",
      name: "'Esra Hofst√© | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.50",
      ons_employee_identifier: "827",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3a735005-78ed-41ce-bb38-f515549c4fee'",
      role: "'hulpverlener'",
      email: "'X.wensing@curess.nl'",
      name: "'Xander Wensing | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2d2492b2-94e6-4673-ae6e-bdbd8b782632'",
      role: "'hulpverlener'",
      email: "'k.nyenkamp@curess.nl'",
      name: "'Klaske Nyenkamp | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'7a261c08-f922-4c29-a4fc-08ca0e9e135a'",
      role: "'hulpverlener'",
      email: "'S.vanschie@curess.nl'",
      name: "'Soraya van Schie | Curess'",
      active: "FALSE",
      ons_location_identifier: "5.14",
      ons_employee_identifier: "161",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8fd9a095-a592-4cee-9bd2-1f0f0bacf8f4'",
      role: "'hulpverlener'",
      email: "'m.lansink@curess.nl'",
      name: "'Marleen Lansink | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.28",
      ons_employee_identifier: "261",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5801dcaa-f693-4bd7-896e-d6d71a24705b'",
      role: "'hulpverlener'",
      email: "'r.gerritsen@curess.nl'",
      name: "'Rianne Gerritsen | Curess'",
      active: "FALSE",
      ons_location_identifier: "4.29",
      ons_employee_identifier: "246",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'52961dc6-075f-45de-b890-5fcf18019379'",
      role: "'hulpverlener'",
      email: "'b.rikken@curess.nl'",
      name: "'Bas Rikken | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.36",
      ons_employee_identifier: "119",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'044d25b4-c192-4300-b173-396b5ce48bad'",
      role: "'hulpverlener'",
      email: "'F.Wullink@curess.nl'",
      name: "'Frederieke  Wullink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'78012a79-a416-4ea0-a761-b96b6b69f710'",
      role: "'hulpverlener'",
      email: "'s.poelakker@curess.nl'",
      name: "'Shannon Poelakker | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f6cda574-1969-40e7-b877-20ae9cc99307'",
      role: "'hulpverlener'",
      email: "'L.heijink@curess.nl'",
      name: "'Lars Heijink | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.37",
      ons_employee_identifier: "190",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'29c83a47-17fc-4cd4-b46d-e1c1dd51e7c5'",
      role: "'hulpverlener'",
      email: "'s.derks@curess.nl'",
      name: "'Sanne Derks | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'921303bd-702c-40d3-916a-e28beeef5327'",
      role: "'hulpverlener'",
      email: "'j.tenveen@curess.nl'",
      name: "'Jeroen ten Veen | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c3309675-7824-40a3-b414-287159700ee1'",
      role: "'hulpverlener'",
      email: "'m.groeneveld@curess.nl'",
      name: "'Maudi Groeneveld | Curess [PersonalArchive]'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3fa95019-f0a3-403b-9c58-a3c1cd9375a8'",
      role: "'hulpverlener'",
      email: "'r.denhoudijker@curess.nl'",
      name: "'Richard den Houdijker | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'28968ae8-65d2-411f-88ec-4cbb796ab662'",
      role: "'hulpverlener'",
      email: "'s.aydin@curess.nl'",
      name: "'Selin Aydin | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'89c83072-8a38-428f-adcb-4d6e1fcbdf54'",
      role: "'hulpverlener'",
      email: "'m.warnshuis@curess.nl'",
      name: "'Marit Warnshuis | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.37",
      ons_employee_identifier: "333",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'760cf7a5-4475-4109-ab5f-e7ed8acb589a'",
      role: "'hulpverlener'",
      email: "'l.hulshof@curess.nl'",
      name: "'Lola Hulshof | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.09",
      ons_employee_identifier: "100",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'45c74e35-5b33-484e-b6d0-d14f66eb0e30'",
      role: "'hulpverlener'",
      email: "'L.tepe@curess.nl'",
      name: "'Lisa Tepe | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.02",
      ons_employee_identifier: "183",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3d396154-417d-4bae-ad28-f4938aff48fc'",
      role: "'hulpverlener'",
      email: "'l.voortman@curess.nl'",
      name: "'Lyan Voortman | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5ed67d70-454a-4c86-9479-a12c523ac024'",
      role: "'hulpverlener'",
      email: "'r.timmermans@curess.nl'",
      name: "'Rick Timmermans | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.15",
      ons_employee_identifier: "304",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'64e125d7-aab4-4a7c-831f-51d65e6fd0a2'",
      role: "'hulpverlener'",
      email: "'w.wissink@curess.nl'",
      name: "'Wendy  Wissink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8fec6cef-7f29-4a74-9611-71034e0b6c68'",
      role: "'hulpverlener'",
      email: "'l.ebbers@curess.nl'",
      name: "'Lotte Ebbers | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.29",
      ons_employee_identifier: "366",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'07089499-bb38-4811-86df-b5feef578621'",
      role: "'hulpverlener'",
      email: "'a.bonvanie@curess.nl'",
      name: "'Anneloes Bonvanie | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'6c2efcb5-21cc-476a-944c-137dc77406f6'",
      role: "'hulpverlener'",
      email: "'b.Elfring@curess.nl'",
      name: "'Bjorn Elfring | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.44",
      ons_employee_identifier: "383",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d2a3d403-8643-4756-a9d0-06912e7440ca'",
      role: "'planner'",
      email: "'c.wermers@curess.nl'",
      name: "'Chantal Wermers | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0d78ac9e-cf21-49a2-930c-671a67e865f6'",
      role: "'hulpverlener'",
      email: "'h.pape@curess.nl'",
      name: "'Henderijn Pape | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.26",
      ons_employee_identifier: "391",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b3b902cc-acb7-4042-b6bc-00cf8061631f'",
      role: "'hulpverlener'",
      email: "'r.otten@curess.nl'",
      name: "'Ruth Otten | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.02",
      ons_employee_identifier: "27",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'40b89dc6-6084-4b87-b8f9-e193eb397f70'",
      role: "'hulpverlener'",
      email: "'m.bruns@curess.nl'",
      name: "'Merel Bruns | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.20",
      ons_employee_identifier: "283",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'aa7feb78-c226-41b3-a771-ff6804364fe4'",
      role: "'hulpverlener'",
      email: "'t.kok@curess.nl'",
      name: "'Tessa Kok | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'92ce2df1-6d78-4719-92fd-8a4d1ebbfb04'",
      role: "'hulpverlener'",
      email: "'r.tielkes@curess.nl'",
      name: "'Romee Tielkes | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8b650997-9e1d-4c01-bd5a-18e7ba2184f1'",
      role: "'hulpverlener'",
      email: "'l.averdijk@curess.nl'",
      name: "'Lisa Averdijk | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a3e7e51f-144b-49f0-9776-1e35d100e850'",
      role: "'hulpverlener'",
      email: "'j.hengeveld@curess.nl'",
      name: "'Jos Hengeveld | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.47",
      ons_employee_identifier: "206",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'90c59b76-a50a-4a8d-ab04-c81485ecd278'",
      role: "'hulpverlener'",
      email: "'e.sabanagic@curess.nl'",
      name: "'Ena Sabanagic | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c9b3512f-b110-48df-90a2-00887ec29358'",
      role: "'hulpverlener'",
      email: "'s.fuad@curess.nl'",
      name: "'Suzan Fuad | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'7083865a-b4a4-451e-a8e2-5bb00a6db740'",
      role: "'hulpverlener'",
      email: "'j.netters@curess.nl'",
      name: "'Jasmijn Netters | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a4bc5073-386f-46b4-8c80-ad9e06bcd91a'",
      role: "'hulpverlener'",
      email: "'m.vanarkel@curess.nl'",
      name: "'Mark van Arkel | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.31",
      ons_employee_identifier: "159",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'27a10091-b3f5-4376-a9c7-98952d177e96'",
      role: "'hulpverlener'",
      email: "'e.dejong@curess.nl'",
      name: "'Else de Jong | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'46ffb4eb-8abb-49ee-a3f5-f624197f3b97'",
      role: "'hulpverlener'",
      email: "'n.egbers@curess.nl'",
      name: "'Niels Egbers | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c4d5d969-ae9b-4b48-b678-dd4329d18431'",
      role: "'hulpverlener'",
      email: "'e.oran@curess.nl'",
      name: "'Elena Oran | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.46",
      ons_employee_identifier: "369",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'71641f27-77b9-4b23-a074-4b3178d5383f'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Marlon Mertens | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3f6ad5c8-7ce9-4ec1-8d12-1e4465b7a385'",
      role: "'hulpverlener'",
      email: "'n.breukers@curess.nl'",
      name: "'Nathalie Breukers | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.26",
      ons_employee_identifier: "310",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c0e88357-4612-47e6-abea-d7b42329d43c'",
      role: "'hulpverlener'",
      email: "'c.sanderman@curess.nl'",
      name: "'Channah Sanderman | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.21",
      ons_employee_identifier: "244",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'7cd96872-4b82-478e-b303-a0fbc9402946'",
      role: "'hulpverlener'",
      email: "'r.heijink@curess.nl'",
      name: "'Rob Heijink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'7d1d4427-f22b-4d3a-a9b3-4e0cc814d136'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 6'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b1f9db02-c9db-4e46-8f2e-35a99f857c56'",
      role: "'team_coordinator'",
      email: "'i.zijlstra@curess.nl'",
      name: "'Iris Zijlstra | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1c074195-485d-4772-8450-a9404c765a70'",
      role: "'hulpverlener'",
      email: "'m.veenstra@curess.nl'",
      name: "'Myrthe Veenstra | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.49",
      ons_employee_identifier: "828",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9edabb33-bf42-470b-8a42-04bdca7c4c5f'",
      role: "'hulpverlener'",
      email: "'w.rensen@curess.nl'",
      name: "'Wies Rensen | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.45",
      ons_employee_identifier: "373",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c2b8661a-6e94-4af6-b76b-40a17bbfc7b6'",
      role: "'hulpverlener'",
      email: "'j.euverman@curess.nl'",
      name: "'Jitske Euverman | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.33",
      ons_employee_identifier: "313",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2247abcc-803b-485e-80e3-1afb4d92f1ae'",
      role: "'hulpverlener'",
      email: "'m.theijink@curess.nl'",
      name: "'Marijn Theijink | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.36",
      ons_employee_identifier: "322",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f5e73b91-88eb-48a6-ac3c-2a75881fb393'",
      role: "'hulpverlener'",
      email: "'b.winters@curess.nl'",
      name: "'Bas Winters | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.18",
      ons_employee_identifier: "237",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'28c65979-d278-4362-88ac-167cae641b01'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team ASH'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'198d500b-6d15-4f1e-9775-75e19d057dd2'",
      role: "'hulpverlener'",
      email: "'a.geverink@curess.nl'",
      name: "'Anne Geverink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'7c83eb64-d363-43a1-8c15-f0fbfef12184'",
      role: "'hulpverlener'",
      email: "'t.oudegroeniger@curess.nl'",
      name: "'Thijs Oude Groeniger | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.22",
      ons_employee_identifier: "142",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0d87cca5-8f80-44b7-9fd9-a852b50d574f'",
      role: "'hulpverlener'",
      email: "'j.deboer@curess.nl'",
      name: "'Jelmer de Boer | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.43",
      ons_employee_identifier: "829",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9064fc59-0610-4b80-b2a4-afb14bcdac04'",
      role: "'hulpverlener'",
      email: "'a.greve@curess.nl'",
      name: "'Anisha Greve | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1121d0d1-f859-4015-bed8-5bcfb2400614'",
      role: "'hulpverlener'",
      email: "'y.baks@curess.nl'",
      name: "'Yvette Baks | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.32",
      ons_employee_identifier: "825",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4508bb44-37b5-4d12-8ba4-7c125897b79c'",
      role: "'hulpverlener'",
      email: "'m.bruil@curess.nl'",
      name: "'Mirjam Bruil | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.33",
      ons_employee_identifier: "393",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c1b8b7fd-c552-4e66-96cd-d6ce22687f01'",
      role: "'team_coordinator'",
      email: "'j.delgrosso@curess.nl'",
      name: "'Jos Del Grosso | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'028cdcd8-f8c6-4c96-94c8-9b9195905d48'",
      role: "'hulpverlener'",
      email: "'l.temmink@curess.nl'",
      name: "'Lynn Temmink | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.48",
      ons_employee_identifier: "314",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d3627158-56bf-4a20-8111-1b4638aaa87d'",
      role: "'hulpverlener'",
      email: "'s.ransing@curess.nl'",
      name: "'Sheema Ransing | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'add75d92-00a7-4018-a65c-b5d4c736c680'",
      role: "'hulpverlener'",
      email: "'j.oudeveldhuis@curess.nl'",
      name: "'Jasmijn Oude Veldhuis | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.21",
      ons_employee_identifier: "241",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b2c39e48-e4bf-49ae-9814-35cfa1ddaae9'",
      role: "'hulpverlener'",
      email: "'r.smit@curess.nl'",
      name: "'Rosalin Smit | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'bb48d49d-2dd0-487f-9118-a47319079b53'",
      role: "'hulpverlener'",
      email: "'p.ebbers@curess.nl'",
      name: "'Pleun Ebbers | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.10",
      ons_employee_identifier: "122",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'33598909-1063-44d6-8279-938eedb8fb91'",
      role: "'hulpverlener'",
      email: "'m.kuipers@curess.nl'",
      name: "'Marvin Kuipers | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.14",
      ons_employee_identifier: "270",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'fe71eb7e-015c-43f6-aa3e-db2ef1c3a7c9'",
      role: "'planner'",
      email: "'k.morsink@curess.nl'",
      name: "'Kaylie Morsink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1831d5d7-6a9c-4a9b-9a5d-777cbbcb0ac6'",
      role: "'hulpverlener'",
      email: "'o.fokker@curess.nl'",
      name: "'Onno Fokker | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.28",
      ons_employee_identifier: "826",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'cd08ccc0-3e30-4764-8f6e-c85a92b31ccf'",
      role: "'hulpverlener'",
      email: "'d.vanderploeg@curess.nl'",
      name: "'Dennis Van der Ploeg | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.27",
      ons_employee_identifier: "278",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'59f8852e-8e6f-4db1-9a36-02d3de8cbb01'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 10 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2af220c5-e6ad-425c-82d0-b36f55406d9a'",
      role: "'hulpverlener'",
      email: "'e.kessels@curess.nl'",
      name: "'Elke Kessels | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.04",
      ons_employee_identifier: "281",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1d2ed85d-7cf1-45f5-b76b-d13031fd608d'",
      role: "'hulpverlener'",
      email: "'r.wopereis@curess.nl'",
      name: "'Rochelle Wopereis | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.22",
      ons_employee_identifier: "286",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5a9b187c-aa58-4361-94a5-0ed5a8738391'",
      role: "'hulpverlener'",
      email: "'r.marsman@curess.nl'",
      name: "'Rosario Marsman | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.40",
      ons_employee_identifier: "318",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ff7d7c25-cc82-48de-aec4-3ba70b2e50da'",
      role: "'hulpverlener'",
      email: "'w.schellekens@curess.nl'",
      name: "'Wouter Schellekens | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.48",
      ons_employee_identifier: "398",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9f2b89bf-756e-463a-b6d9-35437bd4867f'",
      role: "'hulpverlener'",
      email: "'c.slettenhaar@curess.nl'",
      name: "'Charell Slettenhaar | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b5730c86-41ce-4ecd-a978-5e8112bfa964'",
      role: "'hulpverlener'",
      email: "'s.dijkstra@curess.nl'",
      name: "'Sophie Dijkstra | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'990dbc3f-ffb4-4bae-8e21-4c883640757f'",
      role: "'hulpverlener'",
      email: "'d.ooijkaas@curess.nl'",
      name: "'Dehlayla Ooijkaas | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'169ea6db-5af8-4f9e-b69a-84ada35779cd'",
      role: "'hulpverlener'",
      email: "'B.Herdem@curess.nl'",
      name: "'B√ºsra Herdem | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.29",
      ons_employee_identifier: "150",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f31bd01d-62d4-4196-81ab-f8528f30d4fc'",
      role: "'hulpverlener'",
      email: "'profitflow2@curess.nl'",
      name: "'Profit Flow'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8eb8d241-24c0-4826-af36-0338e422ae81'",
      role: "'hulpverlener'",
      email: "'m.schreur@curess.nl'",
      name: "'Merle Schreur | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.09",
      ons_employee_identifier: "115",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'21553e2a-1e7c-4b93-881e-a2e3a085b2e4'",
      role: "'hulpverlener'",
      email: "'i.toebes@curess.nl'",
      name: "'Iris Toebes | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.47",
      ons_employee_identifier: "330",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ecf86e3e-7485-44dd-a8a1-d41e859e0463'",
      role: "'hulpverlener'",
      email: "'C.bekker@curess.nl'",
      name: "'Cynthia Bekker | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.33",
      ons_employee_identifier: "186",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ff205aab-4c6d-46e9-aafb-193d653d3465'",
      role: "'hulpverlener'",
      email: "'c.boerboom@curess.nl'",
      name: "'Chantal Boerboom | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.23",
      ons_employee_identifier: "307",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'6db4e592-27e6-4655-97ca-35e69f13cb92'",
      role: "'hulpverlener'",
      email: "'r.luijmes@curess.nl'",
      name: "'Renske Luijmes | Curess'",
      active: "TRUE",
      ons_location_identifier: "15.4",
      ons_employee_identifier: "327",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ca829d6a-0ad0-4951-9f51-50be0424ba6e'",
      role: "'hulpverlener'",
      email: "'r.leussink@curess.nl'",
      name: "'Rianne Leussink | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.19",
      ons_employee_identifier: "335",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'531fabe8-f4b6-45a9-b2ea-dd94b35bfb06'",
      role: "'hulpverlener'",
      email: "'e.oosterhof@curess.nl'",
      name: "'Eric Oosterhof | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.23",
      ons_employee_identifier: "354",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b46cb11c-3691-4032-9715-fb03ddbd5838'",
      role: "'hulpverlener'",
      email: "'z.tanis@curess.nl'",
      name: "'Zarah Tanis | Curess'",
      active: "TRUE",
      ons_location_identifier: "15.8",
      ons_employee_identifier: "396",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2719af8d-3761-46d9-8f98-eed6e8f287ea'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team GW'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'e81327c4-749e-4174-9e99-d8cc629d96d4'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 2'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'fd9f238a-7fc0-450c-8b9c-80797c4fc12e'",
      role: "'hulpverlener'",
      email: "'e.lansink@curess.nl'",
      name: "'Emmy Lansink | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.42",
      ons_employee_identifier: "331",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'a7c301e0-3428-4395-b3d7-af21c256bcb9'",
      role: "'hulpverlener'",
      email: "'m.bourgondien@curess.nl'",
      name: "'Myrthe Bourgondien | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.26",
      ons_employee_identifier: "336",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'111eefd3-52bd-4358-a8dd-1d759b2831dd'",
      role: "'hulpverlener'",
      email: "'a.roering@curess.nl'",
      name: "'Annelot Roering | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.40",
      ons_employee_identifier: "275",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'37b10f03-848b-4b15-9ea3-db4de5ec78fa'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 7'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5f34cc08-cfaa-4427-bc59-fcf3a457d40c'",
      role: "'hulpverlener'",
      email: "'z.jorissen@curess.nl'",
      name: "'Zo√´  Jorissen | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.38",
      ons_employee_identifier: "242",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f4e51d69-c7d5-4f3a-8881-14c29ae249bd'",
      role: "'hulpverlener'",
      email: "'j.deleeuw@curess.nl'",
      name: "'Jelle de Leeuw | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.50",
      ons_employee_identifier: "357",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'828eedfe-e426-42a0-b063-9e94413e44f4'",
      role: "'planner'",
      email: "'p.hopster@curess.nl'",
      name: "'Petra Hopster | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'96960998-8841-4911-ab72-d469672eee3f'",
      role: "'hulpverlener'",
      email: "'b.kleinlugtenbelt@curess.nl'",
      name: "'Britt Kleinlugtenbelt | Curess'",
      active: "TRUE",
      ons_location_identifier: "10.20",
      ons_employee_identifier: "273",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0cd20de3-3b15-4c78-b4e9-e422d42caef0'",
      role: "'planner'",
      email: "'t.vdheuvel@curess.nl'",
      name: "'Tom van den Heuvel | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'722bda9f-3197-492d-8082-9eb61c5c2f38'",
      role: "'hulpverlener'",
      email: "'l.hengelman@curess.nl'",
      name: "'Laura Hengelman | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.42",
      ons_employee_identifier: "851478",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'e6176b98-21fc-401b-b4b9-f5bbbbc61e61'",
      role: "'planner'",
      email: "'m.skaliks@curess.nl'",
      name: "'Marjolein Skaliks | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f8139f2b-bba2-4a07-a8e7-ead5759bf2f3'",
      role: "'planner'",
      email: "null",
      name: "'Profitflow Planner'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5a5f3586-9e1d-43c4-8c90-024e9aafd37c'",
      role: "'planner'",
      email: "null",
      name: "'Cli√´nt administratie'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f52167fb-6540-45f4-974d-f0a73bf91592'",
      role: "'planner'",
      email: "'caseload@curess.nl'",
      name: "'Caseload | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'af1c5ceb-3922-4340-8831-155c105aee0d'",
      role: "'planner'",
      email: "'m.tewoerd@curess.nl'",
      name: "'Marlis te Woerd | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'efeb904b-52c6-4e0f-9fef-f06bd3723515'",
      role: "'planner'",
      email: "'p.vandapperen@curess.nl'",
      name: "'Priscilla van Dapperen | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2c56efb0-c322-4ac9-9ed7-10dfa78c5cf4'",
      role: "'team_coordinator'",
      email: "'y.dam@curess.nl'",
      name: "'Yonica Dam | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4ade5f1e-d702-4d2b-924c-efce3a2a5a12'",
      role: "'read_only'",
      email: "null",
      name: "'Profitflow Leesrechten'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'07c2533f-9216-470c-a64c-f625f53973b2'",
      role: "'hulpverlener'",
      email: "'B.nijhof@curess.nl'",
      name: "'Bryan Nijhof | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.46",
      ons_employee_identifier: "194",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'480c45c9-dfcc-45fe-a1b6-00d6024da989'",
      role: "'hulpverlener'",
      email: "'d.morales@curess.nl'",
      name: "'Denise Morales | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.07",
      ons_employee_identifier: "234",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ce6f749a-e464-4d6e-ac09-7e26af665931'",
      role: "'hulpverlener'",
      email: "'j.plume@curess.nl'",
      name: "'Janne Plume | Curess'",
      active: "TRUE",
      ons_location_identifier: "15.1",
      ons_employee_identifier: "265",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'153413cc-85b7-4803-9ea1-b62b866e9d0f'",
      role: "'hulpverlener'",
      email: "'s.Goossens@curess.nl'",
      name: "'Sandra Goossens | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.03",
      ons_employee_identifier: "83",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'52d25083-ab94-4422-ba49-1e7777c16dcb'",
      role: "'hulpverlener'",
      email: "'j.nijhoff@curess.nl'",
      name: "'Julia Nijhoff | Curess'",
      active: "TRUE",
      ons_location_identifier: "3.49",
      ons_employee_identifier: "361",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0713413a-7ca7-47ca-998f-08b74a1ea6b2'",
      role: "'hulpverlener'",
      email: "'s.lokhorst@curess.nl'",
      name: "'Stella Lokhorst | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.23",
      ons_employee_identifier: "380",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ece4b89b-084d-434c-b7fe-c2ee2d9a68f5'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 1'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8ada0dd9-6ef2-4b30-9589-4d1776f5579f'",
      role: "'hulpverlener'",
      email: "null",
      name: "'Team 9'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5a826f42-5b1d-4bae-9601-7707b15d48a2'",
      role: "'hulpverlener'",
      email: "'i.jelier@curess.nl'",
      name: "'Imke Jelier | Curess'",
      active: "TRUE",
      ons_location_identifier: "15.6",
      ons_employee_identifier: "370",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8d7ec85a-8b95-4c58-9f23-e8156322c762'",
      role: "'team_coordinator'",
      email: "'profitflow1@curess.nl'",
      name: "'Profit flow'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0901aec4-1de2-4b09-8497-5c9a3c248fe9'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 1 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9e94248a-c213-411e-b462-14f89f16c6fd'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 3 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2e501e42-d908-4e0e-a37e-bd97dd3d3c5e'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 2 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'72ff759c-6df5-49e0-a804-605bde29bc7c'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 4 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5ecc63cd-1363-4dd9-9195-2a6c2a5d01b0'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 6 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'e14e56ec-3d1a-48f9-812a-92af12d435f7'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 8 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'472a49c6-849f-4988-998d-49e00122c60d'",
      role: "'read_only'",
      email: "'s.tenhave@curess.nl'",
      name: "'Sanne ten Have | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b30b6bb2-8f98-41e0-b9f9-dcaa1688499d'",
      role: "'read_only'",
      email: "'e.debruin@curess.nl'",
      name: "'Eddy de Bruin | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'00437d0b-050b-42fe-9563-ce2a0c0b1af3'",
      role: "'read_only'",
      email: "'m.wichman@curess.nl'",
      name: "'Marloes Wichman | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'73039096-8b15-468a-a3a5-4018d644aaa5'",
      role: "'read_only'",
      email: "'s.bossink@curess.nl'",
      name: "'Stephanie Bossink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'81394ab5-794d-4146-ba23-3c1141ea57ed'",
      role: "'hulpverlener'",
      email: "'e.schram@curess.nl'",
      name: "'Ellen Schram | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.36",
      ons_employee_identifier: "367",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4d26cb71-042b-43f7-8ed9-099b0ec22ac5'",
      role: "'hulpverlener'",
      email: "'m.craenmehr@curess.nl'",
      name: "'Monique Craenmehr | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.46",
      ons_employee_identifier: "372",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d17dc118-03da-4459-b74f-4cfd0a9147bc'",
      role: "'hulpverlener'",
      email: "'i.barink@curess.nl'",
      name: "'Inge Barink | Curess'",
      active: "TRUE",
      ons_location_identifier: "12.9",
      ons_employee_identifier: "355",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ad3fb71f-3076-4c47-8962-2f9facd889f7'",
      role: "'hulpverlener'",
      email: "'a.reinerink@curess.nl'",
      name: "'Annemiek Reinerink | Curess'",
      active: "TRUE",
      ons_location_identifier: "5.37",
      ons_employee_identifier: "371",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'32820f54-1648-49d4-b267-c2363d872b7e'",
      role: "'hulpverlener'",
      email: "'i.cimen@curess.nl'",
      name: "'Ilona Cimen | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.44",
      ons_employee_identifier: "381",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b053aa7e-a9a8-4137-9a37-7538425e12ce'",
      role: "'hulpverlener'",
      email: "'c.heuvels@curess.nl'",
      name: "'Cas Heuvels | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'8e26a474-7135-4270-a358-7392aecd93aa'",
      role: "'hulpverlener'",
      email: "'f.booijink@curess.nl'",
      name: "'Floor Booijink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'2234680e-ab57-4ccf-8227-7eeac9958fab'",
      role: "'hulpverlener'",
      email: "'k.janssen@curess.nl'",
      name: "'Kay Janssen | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.22",
      ons_employee_identifier: "374",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'debad687-1f03-4d54-9c70-01a6c5eae099'",
      role: "'hulpverlener'",
      email: "'m.meertens@curess.nl'",
      name: "'Marlon Meertens | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1a411319-ed53-465e-8e35-62740ef8e040'",
      role: "'read_only'",
      email: "null",
      name: "'Directie'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'868ad32f-c438-43b5-bb6a-2e30e5081b84'",
      role: "'read_only'",
      email: "'m.plantinga@curess.nl'",
      name: "'Monique Plantinga | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5048092c-af10-46c1-98f2-ee67bb283fdc'",
      role: "'hulpverlener'",
      email: "'k.vanderveen@curess.nl'",
      name: "'Kyla  van der Veen | Curess'",
      active: "TRUE",
      ons_location_identifier: "9.14",
      ons_employee_identifier: "216",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'73a72f00-ef19-455c-96ae-9c75309d577b'",
      role: "'hulpverlener'",
      email: "'r.hijink@curess.nl'",
      name: "'Rob Hijink | Curess'",
      active: "TRUE",
      ons_location_identifier: "8.45",
      ons_employee_identifier: "390",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'5067720e-6a26-4c1b-9a14-b57871b46b8c'",
      role: "'hulpverlener'",
      email: "'s.schepers@curess.nl'",
      name: "'Solenn Schepers | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b48697d8-c20f-41c1-8ada-008e9a4fc991'",
      role: "'hulpverlener'",
      email: "'s.tennapel@curess.nl'",
      name: "'Selma ten Napel | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'42f79513-9d3a-4220-be0d-8599d1f5a7ef'",
      role: "'hulpverlener'",
      email: "'J.rugenbrink@curess.nl'",
      name: "'Jeffry Rugenbrink | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.18",
      ons_employee_identifier: "187",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'42d812ac-6a01-40eb-a2bd-65fdb7ac7d4c'",
      role: "'hulpverlener'",
      email: "'l.vandevosse@curess.nl'",
      name: "'Lisanne van de Vosse | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.20",
      ons_employee_identifier: "356",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'3f28b4df-d8e5-4f22-85f3-9c2da8c5c3c5'",
      role: "'hulpverlener'",
      email: "'a.bronzewijker@curess.nl'",
      name: "'Anniek Bronzewijker | Curess'",
      active: "TRUE",
      ons_location_identifier: "15.9",
      ons_employee_identifier: "831",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d9e0fa99-3085-473c-9653-48059a919993'",
      role: "'hulpverlener'",
      email: "'luc.eijsink@curess.nl'",
      name: "'Luc Eijsink | Curess'",
      active: "TRUE",
      ons_location_identifier: "14.20",
      ons_employee_identifier: "350",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'1eb38ca0-c31d-402f-ae12-985d74db3f79'",
      role: "'hulpverlener'",
      email: "'m.visser@curess.nl'",
      name: "'Minouk Visser | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.15",
      ons_employee_identifier: "311",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'191b52f0-6e81-4cc2-b1e6-e25539235201'",
      role: "'hulpverlener'",
      email: "'l.vanderzwaag@curess.nl'",
      name: "'Laura van der Zwaag | Curess'",
      active: "TRUE",
      ons_location_identifier: "13.14",
      ons_employee_identifier: "315",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'b4fa20f6-b3ab-444a-bfae-06508893d5d7'",
      role: "'hulpverlener'",
      email: "'e.verhoeven@curess.nl'",
      name: "'Esm√©e Verhoeven | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.43",
      ons_employee_identifier: "351",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'98564a3b-18cb-43fa-8a32-2202fa9f116f'",
      role: "'hulpverlener'",
      email: "'f.vergunst@curess.nl'",
      name: "'Floris Vergunst | Curess'",
      active: "TRUE",
      ons_location_identifier: "4.21",
      ons_employee_identifier: "188",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'9e72b0a1-dccc-40a0-912f-8b6ee9c19b9c'",
      role: "'hulpverlener'",
      email: "'d.ballast@curess.nl'",
      name: "'Denise Ballast | Curess'",
      active: "TRUE",
      ons_location_identifier: "15.7",
      ons_employee_identifier: "377",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'0ee43ce1-e2fd-44a3-a9f1-7998afc57f5d'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 5 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'d78f647a-d0c8-46e8-98b5-27ce0d4669e3'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team ASH - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'470351a2-cb37-42d4-bb7f-50128bbd5bf7'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 7 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'4bf26c05-a6ab-4cb1-b89a-614d68036060'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team ISB - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f4522615-2d5e-44f2-bc74-4d3ca159b87b'",
      role: "'team_coordinator'",
      email: "'m.vrielink@curess.nl'",
      name: "'Marloes Vrielink | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c63265fd-bc45-4cac-9414-b5aa566ae492'",
      role: "'hulpverlener'",
      email: "'j.navis@curess.nl'",
      name: "'Jiska Navis | Curess'",
      active: "TRUE",
      ons_location_identifier: "11.04",
      ons_employee_identifier: "146",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'df2ac637-96b2-4a30-a388-7cc8b753bf74'",
      role: "'team_coordinator'",
      email: "'top1@curess.nl'",
      name: "'Top 1 | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'f29f29e2-8ef7-4e37-9a76-1f9bf99b98dc'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team 11 - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'ec3f0b58-a722-4280-af43-c4f0d8520045'",
      role: "'team_coordinator'",
      email: "null",
      name: "'Team GW - Co√∂rdinator'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'c295a52d-8257-45e0-a9c9-c5b47706e526'",
      role: "'read_only'",
      email: "'m.bonvanie@curess.nl'",
      name: "'Mari√´tte Bonvanie | Curess'",
      active: "TRUE",
      ons_location_identifier: "",
      ons_employee_identifier: "",
      ons_location_id: "",
      ons_employee_id: "",
    },
    {
      id: "'cc8c27fd-14da-4e1e-bc7d-b61ea4d3013b'",
      role: "'hulpverlener'",
      email: "'r.tomas@curess.nl'",
      name: "'Rita Tomas | Curess'",
      active: "TRUE",
      ons_location_identifier: "2.47",
      ons_employee_identifier: "394",
      ons_location_id: "",
      ons_employee_id: "",
    },
  ];
  