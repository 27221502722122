import { useEffect } from "react";

import useLocalStorage from "./useLocalStorage";

const COLOR_SCHEME_QUERY = "(prefers-color-scheme: light)";

interface UseDarkModeOutput {
  isDarkMode: boolean;
  toggleDarkMode: () => void;
  enableDarkMode: () => void;
  disableDarkMode: () => void;
}

function useDarkMode(defaultValue?: boolean): UseDarkModeOutput {
  const getPrefersScheme = (): boolean => {
    // Prevents SSR issues
    if (typeof window !== "undefined") {
      return window.matchMedia(COLOR_SCHEME_QUERY).matches;
    }

    return !!defaultValue;
  };

  const [isDarkMode, setDarkMode] = useLocalStorage<boolean>(
    "darkMode",
    getPrefersScheme()
  );
  if (localStorage.getItem("darkMode") === null) {
    setDarkMode(false);
  }

  // Update darkMode if os prefers changes
  useEffect(() => {
    const handler = () => setDarkMode(getPrefersScheme);
    const matchMedia = window.matchMedia(COLOR_SCHEME_QUERY);

    matchMedia.addEventListener("change", handler);

    return () => {
      matchMedia.removeEventListener("change", handler);
    };
  }, []);

  return {
    isDarkMode,
    toggleDarkMode: () => setDarkMode((prev) => !prev),
    enableDarkMode: () => setDarkMode(true),
    disableDarkMode: () => setDarkMode(false),
  };
}

export default useDarkMode;
