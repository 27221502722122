import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createHttpLink } from "apollo-link-http";
import { getMainDefinition } from "apollo-utilities";
import { useSnackbar } from "notistack";
import React from "react";
import { useAuth } from "react-oidc-context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { WebSocketLink } from '@apollo/client/link/ws';

const AuthorizedApolloProvider = ({ children }: any) => {
  const { enqueueSnackbar } = useSnackbar();
  const httpLink = createHttpLink({
    uri: import.meta.env.VITE_GRAPHQL, // your URI here...
  });
  
  const { user, signinSilent } = useAuth();

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        enqueueSnackbar(`[GraphQL error]: Message: ${message}`, {
          variant: "warning",
        })
      );
    if (networkError)
      enqueueSnackbar(`[Network error]: ${networkError}`, { variant: "error" });
  });
  function getHeaders() {
    const headers: any = {}
    const token = user?.access_token;
    if (token) {
        headers["Authorization"] = `Bearer ${token}`
    }
    return headers
}
  const connectionParams = () => {
    const token = user?.access_token;
    // return token ? { 'x-hasura-admin-secret': 'Welkom01!' } : {};
    return token ? { 'authToken':  token } : {};
    // return token ? { 'Authorization': 'Bearer ' + token } : {};
  };

  // // Create a WebSocket link:
  const wsLink = new WebSocketLink({
    uri: import.meta.env.VITE_GRAPHQL_WS, // use wss for a secure endpoint
    options: {
      reconnect: true,
      lazy: true,
      timeout: 30000,
      inactivityTimeout: 30000,
      connectionParams: {
        headers: getHeaders(),
      },
    },
  });
  
    console.log("🚀 ~ file: AuthorizedApolloProvider.tsx:47 ~ AuthorizedApolloProvider ~ user?.access_token:", user?.access_token)
  // const wsLink = new GraphQLWsLink(
  //   createClient({
  //     url: import.meta.env.VITE_GRAPHQL_WS,
  //     connectionParams: {
        
  //       authToken: `Bearer ${user?.access_token}`,
  //     },
  //   }),
  // );

  const RefreshTokenLink = setContext(async (operation, { headers }) => {
    if (user?.expires_at && user.expires_at * 1000 < Date.now() + 10000) {
      // signinSilent();
      console.log("Token is about to expire or has expired: refreshing token is required");
    }
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${user?.access_token}`,
      },
    };
  });

  const cache = new InMemoryCache({
    typePolicies: {
      User: {
        merge: true,
      },
    },
  });

  // Using the ability to split links, you can send data to each link depending on the kind of operation being sent
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

  const link = split(({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  }, httpLink as any);

  const apolloClient = new ApolloClient({
    link: from([RefreshTokenLink as any, splitLink] as any) as any,
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
