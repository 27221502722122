import { createTheme } from "@mui/material/styles";
import "@fontsource/source-sans-pro"; // Defaults to weight 400.

// To fix findDomNode error until MUI V5 can be used (waiting for MUI datatables)

import { nlNL, enUS } from "@mui/material/locale";
import determinePrimaryColor from "../functions/determinePrimaryColor";

// Global styles can be moved to a separate file for ease of maintenance.
const global = {
  textRight: {
    textAlign: "right",
  },
};

export const dark = createTheme(
  {
    palette: {
      mode: "dark",
      primary: {
        main: determinePrimaryColor(),
        contrastText: "#fff",
      },
      secondary: {
        main: "#9cd3e5",
      },
    },
    typography: {
      fontFamily: "Source Sans Pro",
      button: {
        textTransform: "none", //Using this will stop tranforming all button texts to UPPERCASE
      },
    },
    components: {
      MuiListItem: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: determinePrimaryColor(),
            },
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: `
          @font-face {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-display: swap;
            font-weight: 400;
            unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
          }`,
      },
    },
  },
  enUS,
  nlNL
);

export const light = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: determinePrimaryColor(),
      contrastText: "#fff",
    },
    secondary: {
      main: "#9cd3e5",
    },
  },
  typography: {
    fontFamily: "Source Sans Pro",
  },
  components: {
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: determinePrimaryColor(),
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }`,
    },
  },
});
