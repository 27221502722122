import { proxy } from "valtio";

export const jobProxy = proxy<{
  name: string;
  currentStep: string;
  logs: string[];
  status: "Idle" | "Preparing" | "Running" | "Finished" | "Failed";
}>({
  name: "",
  currentStep: "",
  logs: [],
  status: "Idle",
});
