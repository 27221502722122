import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { IconButton, Typography } from "@mui/material";
import {
  Close,
  DarkMode,
  LightMode,
  Settings,
  SettingsSystemDaydream,
} from "@mui/icons-material";
import ButtonGroup from "@mui/material/ButtonGroup";
import useDarkMode from "../hooks/useDarkMode";

type Anchor = "top" | "left" | "bottom" | "right";

export default function SettingsDrawer() {
  const { enableDarkMode, disableDarkMode, isDarkMode } = useDarkMode();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const buttons = [
    <Button
      variant={!isDarkMode ? "contained" : "outlined"}
      key="one"
      style={{ width: "50%" }}
      onClick={disableDarkMode}
      startIcon={<LightMode />}
    >
      Licht
    </Button>,
    <Button
      variant={isDarkMode ? "contained" : "outlined"}
      key="three"
      style={{ width: "50%" }}
      onClick={enableDarkMode}
      startIcon={<DarkMode />}
    >
      Donker
    </Button>,
  ];

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="open settings"
        onClick={toggleDrawer("right", true)}
      >
        <Settings />
      </IconButton>
      <Drawer
        style={{ zIndex: 2000 }}
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          onKeyDown={toggleDrawer("right", false)}
        >
          <Box
            p={2}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h5">Instellingen</Typography>
            <IconButton onClick={toggleDrawer("right", false)}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box p={2} style={{ display: "flex", alignItems: "center" }}>
            <ButtonGroup
              size="large"
              style={{ width: "100%" }}
              aria-label="large button group"
            >
              {buttons}
            </ButtonGroup>
          </Box>
        </Box>
      </Drawer>
    </div>
  );
}
