import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type GetAllCaseloadContinuationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllCaseloadContinuationsQuery = { case_load_continuation: Array<{ comment?: string | null, complexity?: Types.Case_Load_Continuation_Enum_Enum | null, continuation: boolean, continuation_type?: Types.Type_Enum_Enum | null, created_by?: any | null, created_at: any, current_type?: Types.Type_Enum_Enum | null, evaluated_by?: any | null, hours?: any | null, id: any, is_approved?: boolean | null, weekend?: boolean | null, weekend_str?: string | null, client: { first_name?: string | null, last_name?: string | null, full_name?: string | null, id: number, region_code?: string | null }, created_by_user?: { name?: string | null, team?: { name?: string | null, coordinatorUser?: { name?: string | null } | null } | null } | null, case_load_continuation_related_users: Array<{ user: { role?: Types.Auth_User_Role_Enum_Enum | null, name?: string | null, team?: { name?: string | null, coordinatorUser?: { name?: string | null } | null } | null } }>, case_load_continuation_enum?: { comment: string, value: string } | null }>, case_load_continuation_aggregate: { aggregate?: { count: number } | null } };

export type GetAllCaseloadContinuationsAggregateQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetAllCaseloadContinuationsAggregateQuery = { case_load_continuation_aggregate: { aggregate?: { count: number } | null } };


export const GetAllCaseloadContinuationsDocument = gql`
    query getAllCaseloadContinuations {
  case_load_continuation(order_by: {created_at: desc}) {
    comment
    complexity
    continuation
    continuation_type
    created_by
    created_at
    current_type
    evaluated_by
    hours
    id
    is_approved
    weekend
    weekend_str
    client {
      first_name
      last_name
      full_name
      id
      region_code
    }
    created_by_user {
      name
      team {
        name
        coordinatorUser {
          name
        }
      }
    }
    case_load_continuation_related_users(order_by: {user: {role: desc}}) {
      user {
        role
        name
        team {
          name
          coordinatorUser {
            name
          }
        }
      }
    }
    case_load_continuation_enum {
      comment
      value
    }
  }
  case_load_continuation_aggregate(where: {is_approved: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllCaseloadContinuationsQuery__
 *
 * To run a query within a React component, call `useGetAllCaseloadContinuationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCaseloadContinuationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCaseloadContinuationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCaseloadContinuationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCaseloadContinuationsQuery, GetAllCaseloadContinuationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllCaseloadContinuationsQuery, GetAllCaseloadContinuationsQueryVariables>(GetAllCaseloadContinuationsDocument, options);
      }
export function useGetAllCaseloadContinuationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCaseloadContinuationsQuery, GetAllCaseloadContinuationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllCaseloadContinuationsQuery, GetAllCaseloadContinuationsQueryVariables>(GetAllCaseloadContinuationsDocument, options);
        }
export type GetAllCaseloadContinuationsQueryHookResult = ReturnType<typeof useGetAllCaseloadContinuationsQuery>;
export type GetAllCaseloadContinuationsLazyQueryHookResult = ReturnType<typeof useGetAllCaseloadContinuationsLazyQuery>;
export type GetAllCaseloadContinuationsQueryResult = Apollo.QueryResult<GetAllCaseloadContinuationsQuery, GetAllCaseloadContinuationsQueryVariables>;
export const GetAllCaseloadContinuationsAggregateDocument = gql`
    query getAllCaseloadContinuationsAggregate {
  case_load_continuation_aggregate(where: {is_approved: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetAllCaseloadContinuationsAggregateQuery__
 *
 * To run a query within a React component, call `useGetAllCaseloadContinuationsAggregateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCaseloadContinuationsAggregateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCaseloadContinuationsAggregateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCaseloadContinuationsAggregateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCaseloadContinuationsAggregateQuery, GetAllCaseloadContinuationsAggregateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllCaseloadContinuationsAggregateQuery, GetAllCaseloadContinuationsAggregateQueryVariables>(GetAllCaseloadContinuationsAggregateDocument, options);
      }
export function useGetAllCaseloadContinuationsAggregateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCaseloadContinuationsAggregateQuery, GetAllCaseloadContinuationsAggregateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllCaseloadContinuationsAggregateQuery, GetAllCaseloadContinuationsAggregateQueryVariables>(GetAllCaseloadContinuationsAggregateDocument, options);
        }
export type GetAllCaseloadContinuationsAggregateQueryHookResult = ReturnType<typeof useGetAllCaseloadContinuationsAggregateQuery>;
export type GetAllCaseloadContinuationsAggregateLazyQueryHookResult = ReturnType<typeof useGetAllCaseloadContinuationsAggregateLazyQuery>;
export type GetAllCaseloadContinuationsAggregateQueryResult = Apollo.QueryResult<GetAllCaseloadContinuationsAggregateQuery, GetAllCaseloadContinuationsAggregateQueryVariables>;