import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import { Badge, IconButton } from "@mui/material";
import { NotificationsActive, Delete } from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {
  useLazyQuery,
  useMutation,
  useSubscription,
  useQuery,
} from "@apollo/client";
import {
  GET_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from "../graphql/Notification.graphql";
import { useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";

const NotificationsPopOver = () => {
  const { user } = useAuth();
  const user_id = user?.profile?.azure_id;
  const navigate = useNavigate();

  const [notificationData, setnotificationData] = useState() as any;
  const [notificationCount, setNotificationCount] = useState(0) as any;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { data, loading } = useQuery<any>(GET_NOTIFICATION, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    pollInterval: 10000,
    variables: {
      user_id: user_id,
    },

    onCompleted: (data) => {
      setnotificationData(data.notification);
      setNotificationCount(data.notification_aggregate.aggregate.count);
    },
  });

  // const { } = useQuery<any>(GET_NOTIFICATION_COUNT, {
  //   fetchPolicy: 'network-only',
  //   nextFetchPolicy: 'network-only',
  //   notifyOnNetworkStatusChange: true,
  //   pollInterval: 5000,
  //   variables: {
  //     user_id: user_id,
  //   },
  //   onCompleted: (data) => {
  //     setNotificationCount(
  //       data.notification_aggregate.aggregate.count
  //     );
  //   },
  // });

  const [
    updateUserNotification,
    { data: UpdatedNotificationData, loading: UpdatedNotificationLoading },
  ] = useMutation(UPDATE_NOTIFICATION, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      let notificationID = data?.update_notification_by_pk?.id;
      let notifications: any = JSON.parse(JSON.stringify(notificationData));
      let newNotifications = notifications.filter(
        (notification: any) => notification.id !== notificationID
      );
      setnotificationData(newNotifications);
      setNotificationCount(notificationCount - 1);
    },
  });

  const readNotification = (notificationID: Number) => {
    updateUserNotification({
      variables: {
        id: notificationID,
      },
    });
  };

  return (
    <div>
      <IconButton
        color="inherit"
        aria-label="open settings"
        aria-describedby={id}
        onClick={handleClick}
      >
        <Badge badgeContent={notificationCount} color="secondary">
          <NotificationsActive />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText
                primary={`U heeft op dit moment ${notificationCount == 0 ? "geen" : notificationCount
                  } notificaties`}
              />
            </ListItemButton>
          </ListItem>
          {notificationData?.map((notification: any) => (
            <ListItem
              style={{ color: "inherit" }}
              component="a"
              onClick={() => {
                navigate(notification.link, {});
                // readNotification(notification.id);
                handleClose();
              }}
              disablePadding
              key={notification.id}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={(event: any) => {
                    event.stopPropagation();
                    readNotification(notification.id);
                  }}
                >
                  <Delete />
                </IconButton>
              }
            >
              <ListItemButton key={notification.id}>
                <ListItemText
                  sx={{ maxWidth: "500px" }}
                  key={notification.id}
                  primary={notification.message}
                  secondary="Klik hier om er heen te gaan"
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  );
};

export default NotificationsPopOver;
