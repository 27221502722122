import { Button } from "@mui/material";
import { Job } from "@src/pages/Admin";
import React from "react";
import { teams, Team } from "./static/teams";
import { jobProxy } from "./state";
import { useUpdateTeamLocationMutation } from "@src/graphql/generated/team.mutations.generated";
import { useGetOnsLocationByIdentificationNoLazyQuery } from "@src/graphql/generated/users.queries.generated";

type ActionsProps = {};

const AssignTeamsONS: React.FC<ActionsProps> = () => {
  const [updateTeamLocation, {}] = useUpdateTeamLocationMutation();
  const [mutate, {}] = useGetOnsLocationByIdentificationNoLazyQuery({});

  const handleAssignTeamsONS = async () => {
    jobProxy.name = "AssignTeamsONS";
    jobProxy.status = "Running";
    jobProxy.currentStep = "Starting...";
    jobProxy.logs.push("Starting...");

    for (let index = 0; index < teams.length; index++) {
      const element = teams[index];
      await assignTeam(element);
    }

    jobProxy.status = "Finished";
    jobProxy.currentStep = "Finished";
    jobProxy.logs.push("Finished");
  };

  const assignTeam = async (team: Team) => {
    jobProxy.currentStep = `Assigning team ${team.id} to ONS...`;
    jobProxy.logs.push(`----- Assigning team ${team.id} to ONS... -----`);
    if (team.ons_location_identifier !== "") {
      try {
        const { data } = await mutate({
          variables: {
            identificationNo: team.ons_location_identifier,
          },
        });

        jobProxy.logs.push("mutate succeeded");

        await updateTeamLocation({
          variables: {
            teamId: parseInt(team.id),
            locationId: data!.getOnsLocationsByIdentificationNo!.id as number,
            locationIdentifier:
              data!.getOnsLocationsByIdentificationNo!.identificationNo as string,
          },
        });

        jobProxy.logs.push("updateTeamLocation succeeded");
        jobProxy.logs.push(`Team information updated`);
      } catch (error) {
        jobProxy.logs.push(`[ERROR] Operation failed: ${error}`);
      }
    } else {
      jobProxy.logs.push(`Team does not have an ONS location identifier`);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAssignTeamsONS}
        fullWidth
      >
        Assign teams to ONS
      </Button>
    </div>
  );
};

export default AssignTeamsONS;
