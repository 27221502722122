export type Team = typeof teams[number];

export const teams = [
    {
      id: "71",
      name: "'Team 10'",
      ons_location_identifier: "14.0",
    },
    {
      id: "67",
      name: "'Team ISB'",
      ons_location_identifier: "",
    },
    {
      id: "64",
      name: "'Team 6'",
      ons_location_identifier: "9.0",
    },
    {
      id: "68",
      name: "'Team ASH'",
      ons_location_identifier: "",
    },
    {
      id: "72",
      name: "'Team GW'",
      ons_location_identifier: "12.0",
    },
    {
      id: "73",
      name: "'Team 11'",
      ons_location_identifier: "15.0",
    },
    {
      id: "65",
      name: "'Team 7'",
      ons_location_identifier: "10.0",
    },
    {
      id: "70",
      name: "'Team 9'",
      ons_location_identifier: "13.0",
    },
    {
      id: "59",
      name: "'Team 2'",
      ons_location_identifier: "3.0",
    },
    {
      id: "63",
      name: "'Team 8'",
      ons_location_identifier: "11.0",
    },
    {
      id: "60",
      name: "'Team 1'",
      ons_location_identifier: "2.0",
    },
    {
      id: "61",
      name: "'Team 5'",
      ons_location_identifier: "8.0",
    },
    {
      id: "66",
      name: "'Team 3'",
      ons_location_identifier: "5.0",
    },
    {
      id: "62",
      name: "'Team 4'",
      ons_location_identifier: "4.0",
    },
    {
      id: "69",
      name: "'Test Team'",
      ons_location_identifier: "",
    },
  ];