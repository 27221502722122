import * as Types from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateTeamLocationMutationVariables = Types.Exact<{
  teamId: Types.Scalars['Int'];
  locationId: Types.Scalars['Int'];
  locationIdentifier?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type UpdateTeamLocationMutation = { update_team_by_pk?: { id: number } | null };


export const UpdateTeamLocationDocument = gql`
    mutation UpdateTeamLocation($teamId: Int!, $locationId: Int!, $locationIdentifier: String) {
  update_team_by_pk(
    pk_columns: {id: $teamId}
    _set: {ons_location_id: $locationId, ons_location_identifier: $locationIdentifier}
  ) {
    id
  }
}
    `;
export type UpdateTeamLocationMutationFn = Apollo.MutationFunction<UpdateTeamLocationMutation, UpdateTeamLocationMutationVariables>;

/**
 * __useUpdateTeamLocationMutation__
 *
 * To run a mutation, you first call `useUpdateTeamLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamLocationMutation, { data, loading, error }] = useUpdateTeamLocationMutation({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      locationId: // value for 'locationId'
 *      locationIdentifier: // value for 'locationIdentifier'
 *   },
 * });
 */
export function useUpdateTeamLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTeamLocationMutation, UpdateTeamLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateTeamLocationMutation, UpdateTeamLocationMutationVariables>(UpdateTeamLocationDocument, options);
      }
export type UpdateTeamLocationMutationHookResult = ReturnType<typeof useUpdateTeamLocationMutation>;
export type UpdateTeamLocationMutationResult = Apollo.MutationResult<UpdateTeamLocationMutation>;
export type UpdateTeamLocationMutationOptions = Apollo.BaseMutationOptions<UpdateTeamLocationMutation, UpdateTeamLocationMutationVariables>;