import React, { useState } from "react";
import UserTable from "../components/UserTable";
import { Alert } from "@mui/material";
import Actions from "@src/components/admin/Actions";
import { useProxy } from "valtio/utils";

export type Job = {
  name: string;
  currentStep: string;
  logs: string[];
  status: "Preparing" | "Running" | "Finished" | "Failed";
};

const Admin = () => {
  return (
    <div>
      <Actions/>
    </div>
  );
};

export default Admin;
