import { Button } from "@mui/material";
import { Job } from "@src/pages/Admin";
import React from "react";
import { useSnapshot } from "valtio";
import { jobProxy } from "./state";

import { users, User } from "./static/users";
import {
  useUpdateUserEmployeeMutation,
  useUpdateUserLocationMutation,
} from "@src/graphql/generated/users.mutations.generated";
import {
  useGetOnsEmployeeByIdentificationNoLazyQuery,
  useGetOnsLocationByIdentificationNoLazyQuery,
} from "@src/graphql/generated/users.queries.generated";

type ActionsProps = {};

const AssignUsersONS: React.FC<ActionsProps> = () => {
  const [updateUserLocation, {}] = useUpdateUserLocationMutation();
  const [
    mutateUserLocation,
    { loading: locationLoading, error: locationError },
  ] = useGetOnsLocationByIdentificationNoLazyQuery();

  const [updateUserEmployee, {}] = useUpdateUserEmployeeMutation();
  const [
    mutateUserEmployee,
    { loading: employeeLoading, error: employeeError },
  ] = useGetOnsEmployeeByIdentificationNoLazyQuery();

  const handleAssignUsersONS = async () => {
    jobProxy.name = "AssignUsersONS";
    jobProxy.status = "Running";
    jobProxy.currentStep = "Starting...";
    jobProxy.logs.push("Starting...");

    for (let index = 0; index < users.length; index++) {
      const element = users[index];
      await assignUser(element);
    }

    jobProxy.status = "Finished";
    jobProxy.currentStep = "Finished";
    jobProxy.logs.push("Finished");
  };

  const assignUser = async (user: User) => {
    jobProxy.currentStep = `Assigning user ${user.id} to ONS...`;
    jobProxy.logs.push(`----- Assigning user ${user.id} to ONS... -----`);
    if (user.ons_employee_identifier !== "") {
      try {
        const { data } = await mutateUserEmployee({
          variables: {
            identificationNo: user.ons_employee_identifier,
          },
        });

        jobProxy.logs.push("mutateUserEmployee succeeded");

        await updateUserEmployee({
          variables: {
            userId: user.id.replaceAll("'", ""),
            employeeId: data!.getOnsEmployeesByIdentificationNo!.id as number,
            EmployeeIdentifier: data!.getOnsEmployeesByIdentificationNo!
              .identificationNo as string,
          },
        });

        jobProxy.logs.push("updateUserEmployee succeeded");
        jobProxy.logs.push(`Employee information updated`);
      } catch (error) {
        jobProxy.logs.push(`[ERROR] Operation failed: ${error}`);
      }
    } else {
      jobProxy.logs.push(`User does not have an ONS employee identifier`);
    }

    if (user.ons_location_identifier !== "") {
      try {
        const { data } = await mutateUserLocation({
          variables: {
            identificationNo: user.ons_location_identifier,
          },
        });

        jobProxy.logs.push("mutateUserLocation succeeded");

        await updateUserLocation({
          variables: {
            userId: user.id.replaceAll("'", ""),
            locationId: data!.getOnsLocationsByIdentificationNo!.id as number,
            locationIdentifier: data!.getOnsLocationsByIdentificationNo!
              .identificationNo as string,
          },
        });

        jobProxy.logs.push("updateUserLocation succeeded");
        jobProxy.logs.push(`Location information updated`);
      } catch (error) {
        jobProxy.logs.push(`[ERROR] Operation failed: ${error}`);
      }
    } else {
      jobProxy.logs.push(`User does not have an ONS location identifier`);
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={handleAssignUsersONS}
        fullWidth
      >
        Assign users to ONS
      </Button>
    </div>
  );
};

export default AssignUsersONS;
